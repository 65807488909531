<template> 
  <card
    v-model="item"
    @openAuth="$emit('openAuth')"
    @delete="$emit('delete')"
    @refresh="$emit('refresh')"
    @getExternalLink="$emit('getExternalLink')"
  >
    <template #header>
      <b-row align-v="center" class="d-flex flex-nowrap justify-content-between w-100">

<!-- Generator: Adobe Illustrator 19.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg xmlns="http://www.w3.org/2000/svg" class="sap-svg-logo-header ml-1" viewBox="0 0 1000 650" style="enable-background:new 0 0 1000 494.7" xml:space="preserve">
          <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="295.668" y1="502.214" x2="295.668" y2="403.276" gradientTransform="matrix(5 0 0 -5 -978.34 2511.07)">
            <stop offset="0" style="stop-color:#00aeef"/>
            <stop offset=".212" style="stop-color:#0097dc"/>
            <stop offset=".519" style="stop-color:#007cc5"/>
            <stop offset=".792" style="stop-color:#006cb8"/>
            <stop offset="1" style="stop-color:#0066b3"/>
          </linearGradient>
          <path d="M0 494.7h505.4L1000 0H0v494.7" style="fill-rule:evenodd;clip-rule:evenodd;fill:url(#a)"/>
          <path d="M593.5 98.9h-98.8l.3 232.3-86-232.3h-85.3L250.3 293c-7.8-49.4-58.9-66.4-99-79.2-26.5-8.5-54.7-21.1-54.4-34.9.2-11.4 
            15.1-21.9 44.6-20.3 19.8 1.1 37.3 2.7 72 19.5l34.2-59.6C216 102.2 172.2 92 136.2 92h-.2c-41.9 0-76.8 13.6-98.5 35.9-15.1 
            15.6-23.2 35.5-23.5 57.4-.5 30.2 10.5 51.6 33.8 68.7 19.7 14.4 44.8 23.7 66.9 30.6 27.3 8.5 49.6 15.8 49.4 31.5-.2 5.7-2.4 
            11.1-6.5 15.4-6.8 7-17.3 9.7-31.7 10-27.9.6-48.6-3.8-81.5-23.3L14 378.6c32.9 18.7 67.8 28.1 107.5 28.1l8.9-.1c34.5-.6 62.6-8.9 
            84.8-26.8 1.3-1 2.4-2.1 3.6-3.1l-3.7 19.3 83.3-.3 15-38.3c15.7 5.4 33.6 8.3 52.6 8.3 18.5 0 35.9-2.8 51.3-7.9l10.4 37.8 
            149.5.1.4-87.3h31.8c76.9 0 122.4-39.1 122.4-104.8-.1-72.8-44.3-104.7-138.3-104.7zM366 297.5c-11.5 0-22.3-2-31.5-5.5l31.2-98.4h.6l30.7 
            98.7c-9.3 3.2-19.9 5.2-31 5.2zm233.2-56.6h-21.7v-79.3h21.7c28.9 0 52 9.6 52 39.2 0 30.5-23 40.1-52 40.1" style="fill-rule:evenodd;clip-rule:evenodd;fill:#fff"
          />
        </svg>

        
        <h2 class="px-1 d-flex flex-nowrap">
          <span>
            SAP
          </span>
        </h2>
        <feather-icon
          :icon="active ? 'CheckIcon' : 'XIcon'"
          :class="active ? 'text-primary' : 'text-danger'" 
          size="30"
        />
      </b-row>
    </template>
  </card>
</template>

<script>
import { BImg, BIcon, BRow, BCol } from 'bootstrap-vue'
import Card from '@/layouts/components/ThirdPartyApps/Card.vue'

  export default {
    components: {
      BImg,
      BIcon,
      BRow,
      BCol,
      Card
    },
    props: {
      value: {
        type: Object,
        default: undefined,
      }
    },
    computed: {
      item: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      active() {
        return this.item.token
      }
    }
  }
</script>

<style lang="scss" scoped>
.sap-svg-logo-header{
  display: inline;
  height: 3em;
  
}
.st0{fill:#2687E9;}

</style>